import axios from 'axios';
import router from './router'; // Vue Router를 사용한다고 가정
import store from './store';
import Swal from 'sweetalert2';

const instance = axios.create({
  //baseURL: '/api',
  
  baseURL: 'https://autobook.duckdns.org/api', // API 기본 URL
});

instance.interceptors.request.use((config) => {
  // Vuex에서 세션 타임아웃 값을 가져와 헤더에 추가
  const sessionTimeout = store.state.sessionTimeout;
  
  if (sessionTimeout) {
    config.headers['session-timeout'] = sessionTimeout;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
instance.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;
    if (status === 401) {
        Swal.fire({
            icon: 'warning',
            title: '세션이 만료되었습니다.',
            text: '다시 로그인 해주세요.',
            //confirmButtonText: '확인'
          }).then(() => {
            router.push({ name: 'gate' });
          });

    }
    
    return Promise.reject(error);
  }
);

export default instance;
