import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";
//import axios from '@/axios';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/gate',
        },

        {
            path: '/gate',
            name: 'gate',
            component: () => import('../views/gate.vue'),
            meta: {
                title: '테스트'
            }
        },
        {
            path: '/admin-login',
            name: 'login',
            component: () => import('../views/Login.vue'),
            meta: {
                title: '로그인'
            }
        },
        {
            path: '/user-login',
            name: 'UserLogin',
            component: () => import('../views/UserLogin.vue'),
            meta: {
                title: '로그인'
            }
        },
        {
            path: '/pages/error-404',
            name: 'pages-error-404',
            component: () => import('../views/Error404'),
            meta: {
                title: '404',
            }
        },
        {
            // =============================================================================
            // MASTER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/master/MasterView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/home',
                    name: 'home',
                    component: () => import('../views/Home.vue'),
                    meta: {
                        title: '홈',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/calendar',
                    name: 'calendar',
                    component: () => import('../views/calendar/calendar.vue'),                    
                    meta: {
                        title: '일정',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/map',
                    name: 'map',
                    component: () => import('../views/map/Map.vue'),
                    props: route => ({ fromHome: route.query.fromHome === 'true' }),
                    meta: {
                        title: '지도',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/drone',
                    name: 'droneList',
                    component: () => import('../views/drone/DroneList.vue'),
                    meta: {
                        title: '드론 로그 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/drone-detail',
                    name: 'DroneDetail',
                    component: () => import('../views/drone/DroneDetail.vue'),
                    props: route =>({
                        startdate: route.params.startDate,
                        enddate: route.params.endDate,
                        deviceId: route.params.deviceId,  
                        isFlying: route.params.isFlying,                      
                    }),
                    meta: {
                        title: '드론 로그 상세 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/drone/:id',
                    name: 'droneInfo',
                    component: () => import('../views/drone/DroneInfo.vue'),
                    meta: {
                        title: '드론 로그 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/dronedevice',
                    name: 'droneDeviceList',
                    component: () => import('../views/drone/DroneDeviceList.vue'),
                    meta: {
                        title: '드론 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/dronedevice/:id',
                    name: 'droneDeviceInfo',
                    component: () => import('../views/drone/DroneDeviceInfo.vue'),
                    meta: {
                        title: '드론 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/users',
                    name: 'userList',
                    component: () => import('../views/users/UserList.vue'),
                    meta: {
                        title: '회원 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/user/:id',
                    name: 'userInfo',
                    component: () => import('../views/users/UserInfo.vue'),
                    meta: {
                        title: '회원 정보 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/non-users',
                    name: 'nonUserList',
                    component: () => import('../views/users/NonUserList.vue'),
                    meta: {
                        title: '미승인 회원 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/non-user/:id',
                    name: 'nonUserInfo',
                    component: () => import('../views/users/NonUserInfo.vue'),
                    meta: {
                        title: '미승인 회원 정보 상세',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/sessionTimeout',
                    name: 'sessionTimeout',
                    component: () => import('../views/settings/session.vue'),
                    meta: {
                        title: '세션타임아웃 설정',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/password',
                    name: 'password',
                    component: () => import('../views/settings/ChangePassword.vue'),
                    meta: {
                        title: '패스워드 변경',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/version',
                    name: 'version',
                    component: () => import('../views/settings/Version.vue'),
                    meta: {
                        title: '버전',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/access-log',
                    name: 'access-log',
                    component: () => import('../views/settings/accesslog.vue'),
                    meta: {
                        title: '접속기록 조회',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/certificatemodify',
                    name: 'certificatemodify',
                    component: () => import('../views/certificate/Modify.vue'),
                    meta: {
                        title: '증명서 작성',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/certificateissue',
                    name: 'certificateissue',
                    component: () => import('../views/certificate/Issue.vue'),
                    meta: {
                        title: '회원 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },                
                {
                    path: '/certificatelist',
                    name: 'certificatelist',
                    component: () => import('../views/certificate/List.vue'),
                    meta: {
                        title: '증명서 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/attendance',
                    name: 'attendance',
                    component: () => import('../views/users/Attendance.vue'),
                    meta: {
                        title: '출석 관리',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/batch-read',
                    name: 'batch-read',
                    component: () => import('../views/users/batch_read.vue'),
                    meta: {
                        title: '기수 조회',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/batch-create',
                    name: 'batch-create',
                    component: () => import('../views/users/batch_create.vue'),
                    meta: {
                        title: '기수 등록',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/batch-update',
                    name: 'batch-update',
                    component: () => import('../views/users/batch_update.vue'),
                    meta: {
                        title: '기수 수정',
                        authRequired: true,
                        level: 0,
                    }
                },
                
                {   path: '/insurance-read',
                    name: 'insurance-read',
                    component: () => import('../views/DroneManage/Insurance_read.vue'),
                    meta: {
                        title: '드론 보험 조회',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/insurance-create',
                    name: 'insurance-create',
                    component: () => import('../views/DroneManage/Insurance_create.vue'),
                    props: (route) => ({
                        insurances: route.query.insurances ? JSON.parse(route.query.insurances) : [], // JSON으로 전달받아 파싱
                      }),
                    meta: {
                        title: '드론 보험 등록',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/insurance-updateDelete/:id',
                    name: 'insurance-updateDelete',
                    component: () => import('../views/DroneManage/Insurance_updateDelete.vue'),
                    meta: {
                        title: '드론 보험 수정/삭제',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/safetyCert-read',
                    name: 'safetyCert-read',
                    component: () => import('../views/DroneManage/safetyCert_read.vue'),
                    
                    meta: {
                        title: '안정성인증 조회',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/safetyCert-create',
                    name: 'safetyCert-create',                    
                    component: () => import('../views/DroneManage/safetyCert_create.vue'),
                    props: (route) => ({
                        certs: route.query.certs ? JSON.parse(route.query.certs) : [], // JSON으로 전달받아 파싱
                      }),
                    meta: {
                        title: '안정성인증 등록',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/safetyCert-updateDelete/:id',
                    name: 'safetyCert-updateDelete',
                    component: () => import('../views/DroneManage/safetyCert_updateDelete.vue'),
                    meta: {
                        title: '안정성인증 수정/삭제',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/flightAppv-read',
                    name: 'flightAppv-read',
                    component: () => import('../views/DroneManage/flightAppv_read.vue'),
                    meta: {
                        title: '비행승인 조회',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/flightAppv-create',
                    name: 'flightAppv-create',
                    component: () => import('../views/DroneManage/flightAppv_create.vue'),
                    props: (route) => ({
                        appvs: route.query.appvs ? JSON.parse(route.query.appvs) : [], // JSON으로 전달받아 파싱
                      }),
                    meta: {
                        title: '비행승인 등록',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/flightAppv-updateDelete/:id',
                    name: 'flightAppv-updateDelete',
                    component: () => import('../views/DroneManage/flightAppv_updateDelete.vue'),
                    meta: {
                        title: '비행승인 수정/삭제',
                        authRequired: true,
                        level: 0,
                    }
                },    
                {   path: '/daily-check-test',
                    name: 'daily-check-test',
                    component: () => import('../views/DailyCheckTestView.vue'),
                    meta: {
                        title: 'DailyCheckTestView',
                        authRequired: true,
                        level: 0,
                    }
                },    

                {   path: '/logbookmodify',
                    name: 'logbookmodify',
                    component: () => import('../views/logbook/LogRecord.vue'),
                    meta: {
                        title: '기체비행기록부 작성',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/logbookissue',
                    name: 'logbookissue',
                    component: () => import('../views/logbook/LogList.vue'),
                    meta: {
                        title: '기체비행기록부 발급',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/logbooklist',
                    name: 'logbooklist',
                    component: () => import('../views/logbook/LogView.vue'),
                    meta: {
                        title: '기체비행기록부 리스트',
                        authRequired: true,
                        level: 0,
                    }
                },
                
                {   path: '/formedsms-read',
                    name: 'formedsms-read',
                    component: () => import('../views/sms/fSmsRead.vue'),
                    meta: {
                        title: '정규 메세지 조회',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/formedsms-create',
                    name: 'formedsms-create',
                    component: () => import('../views/sms/fSmsCreate.vue'),
                    meta: {
                        title: '정규 메세지 등록',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/formedsms-UpdateDelete',
                    name: 'formedsms-UpdateDelete',
                    component: () => import('../views/sms/fSmsUpdateDelete.vue'),
                    props: (route) => ({
                        uuid: route.query.uuid,
                        msgId: String(route.query.msgId),
                        msgName: route.query.msgName,
                        msgContent: route.query.msgContent
                      }),
                    meta: {
                        title: '정규 메세지 수정/삭제',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/sms-create',
                    name: 'sms-create',
                    component: () => import('../views/sms/smsCreate.vue'),
                    meta: {
                        title: '발송 메세지 등록',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/sms-read',
                    name: 'sms-read',
                    component: () => import('../views/sms/smsRead.vue'),
                    meta: {
                        title: '발송 결과 조회',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/sms-updateDelete',
                    name: 'sms-updateDelete',
                    component: () => import('../views/sms/smsUpdateDelete.vue'),
                    //props:true,
                    //props: (route)=>({ messageData: route.params.messageData}),
                    // props: (route) => ({
                    //     messageData: JSON.parse(route.query.messageData || "{}"),
                    //   }),
                    props: (route) => ({
                        cmid: route.query.cmid,
                        requestTime: route.query.requestTime,
                        sendTime: route.query.sendTime,
                        senderPhone: route.query.senderPhone,
                        receiverPhone: route.query.receiverPhone,
                        messageBody: route.query.messageBody,
                        status: route.query.status,
                        errorCode: route.query.errorCode,
                    }),
                    meta: {
                        title: '발송 메세지 수정/삭제',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/sms-period-read',
                    name: 'sms-period-read',
                    component: () => import('../views/sms/smsPeriodRead.vue'),
                    meta: {
                        title: '발송 주기 조회',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/sms-period-create',
                    name: 'sms-period-create',
                    component: () => import('../views/sms/smsPeriodCreate.vue'),
                    // props: (route) => ({
                    //     existingPeriods: route.query.existingPeriods
                    //       ? JSON.parse(route.query.existingPeriods)
                    //       : [],
                    //   }),
                    meta: {
                        title: '발송 주기 등록',
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/sms-period-updateDelete',
                    name: 'sms-period-updateDelete',
                    component: () => import('../views/sms/smsPeriodUpdateDelete.vue'),
                    props: (route) => ({
                        selectedRecord: route.query.selectedRecord
                            ? JSON.parse(route.query.selectedRecord)
                            : null,
                    }),
                    meta: {
                        title: '발송 주기 수정/삭제',
                        authRequired: true,
                        level: 0,
                    },
                },           
                {   path: '/admin-read',
                    name: 'admin-read',
                    component: () => import('../views/admin/adminRead.vue'),
                    meta: {
                        title: '관리자 조회',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/admin-create',
                    name: 'admin-create',
                    component: () => import('../views/admin/adminCreate.vue'),
                    meta: {
                        title: '관리자 등록',
                        authRequired: true,
                        level: 0,
                    }
                },
                {   path: '/admin-updateDelete',
                    name: 'admin-updateDelete',
                    component: () => import('../views/admin/adminUpdateDelete.vue'),
                    meta: {
                        title: '관리자 수정/삭제',
                        authRequired: true,
                        level: 0,
                    },
                    props: (route) => ({
                        adminData: route.params.adminData, // adminData 객체를 props로 전달
                      }),
                },

                {   path: '/master-airfieldRead',
                    name: 'master-airfieldRead',
                    component: () => import('../views/master/airfield_read.vue'),
                    meta: {
                        title: '비행장소 조회',
                        authRequired: true,
                        level: 0,
                    },
                   
                },
                {   path: '/master-airfieldCreate',
                    name: 'master-airfieldCreate',
                    component: () => import('../views/master/airfield_create.vue'),
                    meta: {
                        title: '비행장소 등록',
                        authRequired: true,
                        level: 0,
                    },
                   
                },
                {   path: '/master-airfieldUpdateDelete/:id',
                    name: 'master-airfieldUpdateDelete',
                    component: () => import('../views/master/airfield_updateDelete.vue'),
                    meta: {
                        title: '비행장소 수정/삭제',
                        authRequired: true,
                        level: 0,
                    },
                    props: true
                },
                {   path: '/master-flightPurposeRead',
                    name: 'master-flightPurposeRead',
                    component: () => import('../views/master/flightPurpose_read.vue'),
                    meta: {
                        title: '비행목적 조회',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-flightPurposeCreate',
                    name: 'master-flightPurposeCreate',
                    component: () => import('../views/master/flightPurpose_create.vue'),
                    meta: {
                        title: '비행목적 등록',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-flightPurposeUpdateDelete',
                    name: 'master-flightPurposeUpdateDelete',
                    component: () => import('../views/master/flightPurpose_updateDelete.vue'),
                    meta: {
                        title: '비행목적 수정/삭제',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-instructorRead',
                    name: 'master-instructorRead',
                    component: () => import('../views/master/instructor_read.vue'),
                    meta: {
                        title: '교관정보 조회',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-instructorCreate',
                    name: 'master-instructorCreate',
                    component: () => import('../views/master/instructor_create.vue'),
                    meta: {
                        title: '교관정보 등록',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-instructorUpdateDelete',
                    name: 'master-instructorUpdateDelete',
                    component: () => import('../views/master/instructor_updateDelete.vue'),
                    meta: {
                        title: '교관정보 수정/삭제',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-certOrganRead',
                    name: 'master-certOrganRead',
                    component: () => import('../views/master/certOrgan_read.vue'),
                    meta: {
                        title: '발급기관 조회',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-certOrganCreate',
                    name: 'master-certOrganCreate',
                    component: () => import('../views/master/certOrgan_create.vue'),
                    meta: {
                        title: '발급기관 등록',
                        authRequired: true,
                        level: 0,
                    },
                    
                }, //master-certOrganUpdateDelete
                {   path: '/master-certOrganUpdateDelete',
                    name: 'master-certOrganUpdateDelete',
                    component: () => import('../views/master/certOrgan_updateDelete.vue'),
                    meta: {
                        title: '발급기관 수정/삭제',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-droneTypeRead',
                    name: 'master-droneTypeRead',
                    component: () => import('../views/master/droneType_read.vue'),
                    meta: {
                        title: '드론타입 조회',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-droneTypeCreate',
                    name: 'master-droneTypeCreate',
                    component: () => import('../views/master/droneType_create.vue'),
                    meta: {
                        title: '드론타입 등록',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                {   path: '/master-droneTypeUpdateDelete',
                    name: 'master-droneTypeUpdateDelete',
                    component: () => import('../views/master/droneType_updateDelete.vue'),
                    meta: {
                        title: '드론타입 등록',
                        authRequired: true,
                        level: 0,
                    },
                    
                },
                
                {
                    path: '/UserHome',
                    name: 'UserHome',
                    component: () => import('../views/UserMode/UserHome.vue'),
                    meta: {
                        title: '사용자 홈',
                        authRequired: true,
                        role: 'user'
                    }
                },
                {
                    path: '/Intro',
                    name: 'Intro',
                    component: () => import('../views/UserMode/Intro.vue'),
                    meta: {
                        title: '교육원 소개',
                        authRequired: true,
                        role: 'user'
                    }
                },
                {
                    path: '/Schedule',
                    name: 'Schedule',
                    component: () => import('../views/UserMode/Schedule.vue'),
                    meta: {
                        title: '교육일정',
                        authRequired: true,
                        role: 'user'
                    }
                },
                {
                    path: '/MyPage',
                    name: 'MyPage',
                    component: () => import('../views/UserMode/MyPage.vue'),
                    meta: {
                        title: '마이페이지',
                        authRequired: true,
                        role: 'user'
                    }
                },
            ],
        },
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ]
})

const makeTitle = (title) =>
    title ? `${title} | DRONE` : "DRONE";

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = makeTitle(to.meta.title);
    });
});


// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
    
    let token = null;
    if (sessionStorage.token !== undefined) {
        token = JSON.parse(sessionStorage.token);
    }
    // await store.dispatch('setAuth', token)
    //     .then(() => {
    //         if (token) {
    //             console.log(token)
    //         } else {
    //             console.log('없다.')
    //         }
    //     })

    if (token) {
        const role = sessionStorage.getItem("userRole");
        store.dispatch("updateAdminMode", role === "admin");

        let token = null;
        if (sessionStorage.token !== undefined) {
            token = JSON.parse(sessionStorage.token);
        }
        try {
            // 토큰 유효성 확인 요청을 보냅니다.
            //await axios.get('/auth/validate-token');
            await store.dispatch('setAuth', token);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert('세션이 만료되었습니다. 다시 로그인 해주세요.');
                sessionStorage.removeItem('token');
                return router.replace({ path: '/' });
            }
        }
    }

    if (to.meta.authRequired) {
        if (!token) {
            await router.replace({path: '/'}).catch(() => true)
        }
        
        //데이터가 없는 상태로 증명서 작성 페이지 이동 방지
        if (to.path ==='/certificatemodify' && !to.params.source) {
            next(false);
        } else {
            next();
        }
        
        //데이터가 없는 상태로 기록부 작성 페이지 이동 방지
        if (to.path ==='/logbookmodify' && !to.params.source) {
            next(false);
        } else {
            next();
        }

    } else {
        if (token) {
            if (to.path === '/home' && from.path === '/home') {
                // 현재 경로가 /home이고 이전 경로도 /home일 때
                next(false); // 내비게이션 취소
            } else if (to.path !== '/home' && to.path !== '/pages/error-404') {
                next({ path: '/home' });   // '/home'으로 리다이렉트
            } else {
                next();  // 다음 라우트로 진행
            }
        } else {
            next();  // 토큰이 없으면 다음으로 진행
        }
    }
    //next()
});

export default router
