import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import axios from '@/axios';
import { getDateTimeSecs } from "@/lib/prettyDate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],  // 상태를 로컬 스토리지에 저장
  state: {
    user: null,
    token: null,
    logs: [],
    selectedDate: '',
    deviceId:'',
    devices: [],
    currentPage: 1,
    location: { lat:null, lng: null},
    weather:null,
    sessionTimeout: sessionStorage.getItem('sessionTimeout') || '86400s',
    //events: [],  // 추가된 상태
    isAdmin: false,
    senderNumber: process.env.VUE_APP_SENDER_NUMBER  || '',
    smsData:{
      lastCheckedDate: "",
      expectedCount: 0,
      currentCount: 0,
    },
    isLocked:false,

  },
  mutations: {
    SET_LOCK(state, payload){
      state.isLocked = payload;
    },
    SET_SMS_DATA(state, payload) {
      state.smsData = payload;
      sessionStorage.setItem("smsData", JSON.stringify(payload));
    },
    UPDATE_SMS_DATA(state, updatedFields) {
      state.smsData = {
        ...state.smsData,
        ...updatedFields,
      };
      sessionStorage.setItem("smsData", JSON.stringify(state.smsData));
    },
    LOAD_SMS_DATA(state) {
      const savedData = sessionStorage.getItem("smsData");
      if (savedData) {
        state.smsData = JSON.parse(savedData);
      }
    },
    RESET_SMS_DATA(state) {
      const initialData = {
        lastCheckedDate: "",
        expectedCount: 0,
        currentCount: 0,
      };
      state.smsData = initialData;
      sessionStorage.setItem("smsData", JSON.stringify(initialData));
    },
    setSenderNumber(state, senderNumber) {
      state.senderNumber = senderNumber;
      sessionStorage.setItem('senderNumber', senderNumber); // sessionStorage에 저장
    },
    updateAdminMode(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setLocation(state, payload) {
      state.location.lat = payload.lat;
      state.location.lng = payload.lng;
      sessionStorage.setItem('userLocation', JSON.stringify(state.location));
    },
    setSessionTimeout(state, timeout) {    
      state.sessionTimeout = timeout;
      sessionStorage.setItem('sessionTimeout', timeout); // 로컬 스토리지에 저장
      axios.defaults.headers.common['session-timeout'] = timeout;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setLogs(state, logs) {
      state.logs = logs;
    },
    setSelectedDate(state, date) {
      state.selectedDate = date;
    },
    setdeviceId(state, deviceId){
      state.deviceId = deviceId;
    },
    setDevices(state, devices){
      state.devices = devices;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    initializeLocation(state) {
      const savedLocation = sessionStorage.getItem('userLocation');
      if (savedLocation) {
        state.location = JSON.parse(savedLocation);
      }
    },
    setWeather(state, data){
      state.weather = data;
    },
    // ADD_EVENT(state, event) {  // 이벤트 추가
    //   state.events.push(event);
    // },
    // UPDATE_EVENT(state, { id, title, start, end }) {  // 이벤트 업데이트
    //   const index = state.events.findIndex(_event => _event.id === id);
    //   if (index !== -1) {
    //     state.events[index] = { ...state.events[index], title, start, end };
    //   }
    // },
    // DELETE_EVENT(state, id) {  // 이벤트 삭제
    //   state.events = state.events.filter(event => event.id !== id);
    // }
  },
  actions: {
    updateSenderNumber({ commit }, newNumber) {
      commit('setSenderNumber', newNumber);
    },
    saveSmsData({ commit }, data) {
      commit("SET_SMS_DATA", data);
    },
    updateSmsData({ commit }, updatedFields) {
      commit("UPDATE_SMS_DATA", updatedFields);
    },
    loadSmsData({ commit }) {
      commit("LOAD_SMS_DATA");
    },
    resetSmsData({ commit }) {
      commit("RESET_SMS_DATA");
    },
    updateAdminMode({ commit }, isAdmin) {
      commit('updateAdminMode', isAdmin);
    },
    fetchLocation({ commit }) {
      commit('initializeLocation');
    },
    setWeatherData({ commit }, weather) {
      commit('setWeather', weather);
    },
    async setAuth({ commit }, data) {
      commit('setToken', data);
    },
    updateSessionTimeout({ commit }, timeout) {
      commit('setSessionTimeout', timeout);
    },
    fetchLogs({ commit, state }) {
      const config = {
        method: 'get',
        url: '/locations/DroneLog',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token.access_token}`
        },
        params: { date: state.selectedDate , deviceId: state.deviceId }
      };
      
      axios(config)
        .then(response => {
          const data = response.data;
          const logs = data.flatMap(item => item.data);
          logs.forEach(item => {
            item.startDate = getDateTimeSecs(new Date(item.startDate));
            item.endDate = getDateTimeSecs(new Date(item.endDate));
            item.isFlying = new Date(item.endDate).getFullYear() === 1970;
          });

          logs.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
          const devices = logs.reduce((acc, log) => {
            if (!acc.find(item => item.id === log.deviceId)) {
              acc.push({ id: log.deviceId });
            }
            return acc;
          }, []);
          commit('setDevices', devices);          
          commit('setLogs', logs);
        })
        .catch(error => {
          console.error(error);
        });
    },
    // addEvent({ commit }, event) {  // 이벤트 추가 액션
    //   commit('ADD_EVENT', event);
    // },
    // updateEvent({ commit }, event) {  // 이벤트 업데이트 액션
    //   commit('UPDATE_EVENT', event);
    // },
    // deleteEvent({ commit }, id) {  // 이벤트 삭제 액션
    //   commit('DELETE_EVENT', id);
    // }
  },
  getters: {
    token: state => state.token,
    logs: state => state.logs,
    selectedDate: state => state.selectedDate,
    deviceId: state => state.deviceId,
    devices: state => state.devices,
    currentPage: state => state.currentPage,
    currentLocation: state => state.location,
    //events: state => state.events,  // 추가된 getter
    isAdmin: state => state.isAdmin,
    smsData: (state) => state.smsData,
    isLocked: (state) => state.isLocked,
  }
});
